(function($){
	var baseUrl = 'https://www.googleapis.com/calendar/v3/calendars/',
		apiKey = 'AIzaSyDS-xMiSS5oIR87RdPsKq3Nz65i0YRGCRc',
		calendarData = [],
		events = [],
		allCalendars = [],
		eventsToDisplay = 4,
		totalEventstoDisplay = 20;
	
	/* Get all the calendars that we have registered */
	function getCalendars() {
		return $.getJSON(ajaxurl, {action: 'pb_get_calendars'})
				.then(function(calendars){
					allCalendars = calendars;
					var promises = calendars.map(function(calendar) {
		                return getCalendarEvents(calendar.googleCalendarId);
		            });
		            return $.when.apply($, promises).then(function() {
		                // convert arguments to a single array as our resolved value
		                return [].slice.call(arguments);
		            });
				});
	}
	
	/* 	Get the events for a calendar by the calendar ID */
	function getCalendarEvents(calendarID) {
		return $.getJSON(baseUrl + calendarID + '/events', {
			maxResults: eventsToDisplay,
			orderBy: 'startTime',
			timeMin: moment().format(),
			singleEvents: true,
			key: apiKey
		}).then(function(data){
			return data.items;
		});
	}
	
	function getCalendar(calendarId) {
		var cal = allCalendars.filter(function(v){
			return calendarId === v.googleCalendarId;
		});
		return cal[0];
	}
	
	
	getCalendars().then(concatEvents);
	
	function concatEvents(allEvents) {
		var eventsList = [].concat.apply([], allEvents);
		eventsList.sort(compare);
		renderEvents(eventsList.slice(0, totalEventstoDisplay));	
	}
	
	function renderEvents(events) {
		var rootElement = React.createElement('div', {}, createEventList(events));
		var eventLists = document.querySelectorAll('.eventslist');
		[].forEach.call(eventLists, function(eventList) {
		  // do whatever
		  ReactDOM.render(rootElement, eventList);
		});
		
	}
	
	function compare(a, b) {
		a.start.moment = a.start.dateTime ? moment(a.start.dateTime) : moment(a.start.date);
		b.start.moment = b.start.dateTime ? moment(b.start.dateTime) : moment(b.start.date);
		
		if (a.start.moment.isBefore(b.start.moment))
			return -1;
		if (b.start.moment.isBefore(a.start.moment))
		    return 1;
		return 0;
	}
	
	function createEventList(events) {
	  return events.map(function(event) {
	  	var calendar = getCalendar(event.organizer.email);
	    return React.createElement('a',  {href: event.htmlLink, target: '_blank', className: 'st-event-wrapper', key: event.id, style: {borderColor: calendar.color}},
	      React.createElement('div', {className: 'st-event-event-marker-wrapper', style: {background: calendar.color}}),
	      React.createElement('div', {className: 'st-event-details-wrapper'},
	      	React.createElement('div', {className: 'st-event__title'}, event.summary ),
	      	React.createElement('div', {className: 'st-event__cat', style: {color: calendar.color}}, calendar.name),
	      	React.createElement('span', {className: 'st-event-date__day'}, event.start.moment.format('MMM D') ),
	      	React.createElement('span', {className: 'st-event__separator'}, '|' ),
	      	React.createElement('span', {className: 'st-event__time'}, 
	      		event.start.date ? 'All Day' : event.start.moment.format('h:mm A')  + ' - ' + moment(event.end.dateTime).format('h:mm A')
	      	)
	      )
	    )
	  })
	}

})(jQuery);